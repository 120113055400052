<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    include: []
  }),
  components: {
  },
  watch: {
    $route(to, from) {
      console.log(to,from);
      if (to.path=='/index' && from.path=='/') {
        to.meta.keepAlive=false;
      }else if(to.path=='/index'){
        to.meta.keepAlive=true;
      }
      localStorage.setItem('fromPath',from.path)
    }
  }
}
</script>

<style>
*{ padding: 0; margin: 0; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
