/*
 * @Author: icker yinluobing@163.com
 * @Date: 2022-08-01 15:23:45
 * @LastEditors: icker
 * @LastEditTime: 2023-01-16 21:36:51
 */
import axios from 'axios';
import { Toast } from 'vant';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const isAdmin = localStorage.getItem('isAdmin');
    if (token) {
      if (isAdmin) {
        config.headers['Authorization'] = 'Bearer '+token; // 让每个请求携带自定义token 请根据实际情况自行修改
      }else{
        config.headers['token'] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code || 0;
    const msg = res.data.msg || 0;
    if (code == 401 || code == -401) {
      Toast(msg);
      localStorage.removeItem('token');
      localStorage.removeItem('openid');
      localStorage.removeItem('isAdmin');
      if (location.href.indexOf('/#/')>-1 && location.href.indexOf('/?redirectUrl=')==-1) {
        var hrefArr = location.href.replace('/?redirectUrl=', '').split('/#/');
        console.log('/'+hrefArr[hrefArr.length - 1],encodeURIComponent('/'+hrefArr[hrefArr.length - 1]))
        location.href = '/#/?redirectUrl=' + encodeURIComponent('/'+hrefArr[hrefArr.length - 1]);
      }else{
        location.href = '/#/';
      }
    }
    // 获取错误信息
    return res.data;
  },
  (error) => {
    console.log('err' + error);
    return Promise.reject(error);
  }
);

export default service;
