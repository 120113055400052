/*
 * @Author: icker yinluobing@163.com
 * @Date: 2022-08-01 12:31:30
 * @LastEditors: icker
 * @LastEditTime: 2023-01-26 22:39:17
 */
import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import router from './router'
import request from './assets/js/request'
import 'vant/lib/index.css';
import './assets/css/style.css';
import { Lazyload } from 'vant';
import clipboard from 'clipboard';
var Stick = require('../vue-stick')

Vue.use(Stick);
Vue.use(Lazyload);

Vue.config.productionTip = false
Vue.prototype.request = request
Vue.prototype.clipboard = clipboard;

// Vue.prototype.apiUrl = process.env.VUE_APP_BASE_API
// Vue.prototype.getUrl = (key)=>{
//   var urls = {
//     list:[
//       "/ayi/list",
//       "/jiazheng/jiazhengAyi/list",
//     ],
//     get:[
//       "/ayi/get",
//       "/jiazheng/jiazhengAyi/get",
//     ]
//   };
//   if (localStorage.getItem('isAdmin')) {
//     return process.env.VUE_APP_BASE_API + urls[key][1]
//   }else{
//     return process.env.VUE_APP_BASE_API + urls[key][0]
//   }
// }
Vue.use(Vant)
 

// 从element-ui css
import 'element-ui/lib/theme-chalk/index.css';

new Vue({
  el: '#app',
  components: {
		Stick: Stick.component
	},
  router,
  render: h => h(App)
}).$mount('#app')

